import * as i0 from '@angular/core';
import { Injectable, NgModule } from '@angular/core';
import { ASM_FEATURE } from '@spartacus/asm/root';
import * as i1 from '@spartacus/core';
import { getContextParameterDefault, LANGUAGE_CONTEXT_ID, CURRENCY_CONTEXT_ID, provideDefaultConfig, facadeFactory } from '@spartacus/core';
import { PageComponentModule } from '@spartacus/storefront';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ASM_CUSTOMER_360_FEATURE = 'asmCustomer360';
const ASM_CUSTOMER_360_CORE_FEATURE = 'asmCustomer360Core';
class SiteContextInterceptor {
  constructor(languageService, currencyService, occEndpoints, config) {
    this.languageService = languageService;
    this.currencyService = currencyService;
    this.occEndpoints = occEndpoints;
    this.config = config;
    this.activeLang = getContextParameterDefault(this.config, LANGUAGE_CONTEXT_ID);
    this.activeCurr = getContextParameterDefault(this.config, CURRENCY_CONTEXT_ID);
    this.languageService.getActive().subscribe(data => this.activeLang = data);
    this.currencyService.getActive().subscribe(data => {
      this.activeCurr = data;
    });
  }
  intercept(request, next) {
    if (request.url.includes(this.occEndpoints.getBaseUrl({
      prefix: false,
      baseSite: false
    })) && request.url.includes('/assistedservicewebservices/')) {
      request = request.clone({
        setParams: {
          lang: this.activeLang ?? '',
          curr: this.activeCurr ?? ''
        }
      });
    }
    return next.handle(request);
  }
  static {
    this.ɵfac = function SiteContextInterceptor_Factory(t) {
      return new (t || SiteContextInterceptor)(i0.ɵɵinject(i1.LanguageService), i0.ɵɵinject(i1.CurrencyService), i0.ɵɵinject(i1.OccEndpointsService), i0.ɵɵinject(i1.SiteContextConfig));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: SiteContextInterceptor,
      factory: SiteContextInterceptor.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SiteContextInterceptor, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.LanguageService
  }, {
    type: i1.CurrencyService
  }, {
    type: i1.OccEndpointsService
  }, {
    type: i1.SiteContextConfig
  }], null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
class AsmCustomer360RootModule {
  static {
    this.ɵfac = function AsmCustomer360RootModule_Factory(t) {
      return new (t || AsmCustomer360RootModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: AsmCustomer360RootModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [provideDefaultConfig({
        featureModules: {
          [ASM_CUSTOMER_360_FEATURE]: {
            dependencies: [ASM_FEATURE]
          },
          [ASM_CUSTOMER_360_CORE_FEATURE]: ASM_CUSTOMER_360_FEATURE
        }
      }), {
        provide: HTTP_INTERCEPTORS,
        useExisting: SiteContextInterceptor,
        multi: true
      }],
      imports: [PageComponentModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AsmCustomer360RootModule, [{
    type: NgModule,
    args: [{
      imports: [PageComponentModule],
      providers: [provideDefaultConfig({
        featureModules: {
          [ASM_CUSTOMER_360_FEATURE]: {
            dependencies: [ASM_FEATURE]
          },
          [ASM_CUSTOMER_360_CORE_FEATURE]: ASM_CUSTOMER_360_FEATURE
        }
      }), {
        provide: HTTP_INTERCEPTORS,
        useExisting: SiteContextInterceptor,
        multi: true
      }]
    }]
  }], null, null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
class AsmCustomer360Facade {
  static {
    this.ɵfac = function AsmCustomer360Facade_Factory(t) {
      return new (t || AsmCustomer360Facade)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: AsmCustomer360Facade,
      factory: () => (() => facadeFactory({
        facade: AsmCustomer360Facade,
        feature: ASM_CUSTOMER_360_FEATURE,
        methods: ['get360Data']
      }))(),
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AsmCustomer360Facade, [{
    type: Injectable,
    args: [{
      providedIn: 'root',
      useFactory: () => facadeFactory({
        facade: AsmCustomer360Facade,
        feature: ASM_CUSTOMER_360_FEATURE,
        methods: ['get360Data']
      })
    }]
  }], null, null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
class AsmCustomer360SectionConfig {}

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
class AsmCustomer360SectionData {
  constructor(data) {
    this.data = data;
  }
}

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
class AsmCustomer360TabConfig {}

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
class AsmCustomer360TabsConfig {}

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
var AsmCustomer360Type;
(function (AsmCustomer360Type) {
  AsmCustomer360Type["REVIEW_LIST"] = "c360ReviewList";
  AsmCustomer360Type["STORE_LOCATION"] = "c360StoreLocation";
  AsmCustomer360Type["PRODUCT_INTEREST_LIST"] = "c360CustomerProductInterestList";
  AsmCustomer360Type["SUPPORT_TICKET_LIST"] = "c360TicketList";
  AsmCustomer360Type["CUSTOMER_PROFILE"] = "c360CustomerProfile";
  AsmCustomer360Type["ACTIVE_CART"] = "c360Cart";
  AsmCustomer360Type["SAVED_CART"] = "c360SavedCart";
  AsmCustomer360Type["OVERVIEW"] = "c360Overview";
  AsmCustomer360Type["ACTIVITY_LIST"] = "c360ActivityList";
  AsmCustomer360Type["COUPON_LIST"] = "c360CouponList";
  AsmCustomer360Type["PROMOTION_LIST"] = "c360PromotionList";
  AsmCustomer360Type["CUSTOMER_COUPON_LIST"] = "c360CustomerCouponList";
})(AsmCustomer360Type || (AsmCustomer360Type = {}));
var PaymentCardCode;
(function (PaymentCardCode) {
  PaymentCardCode["VISA"] = "visa";
  PaymentCardCode["MASTER"] = "master";
  PaymentCardCode["MASTERCARD_EUROCARD"] = "mastercard_eurocard";
  PaymentCardCode["DINERS"] = "diners";
  PaymentCardCode["AMEX"] = "amex";
})(PaymentCardCode || (PaymentCardCode = {}));
var KeyBoardEventCode;
(function (KeyBoardEventCode) {
  KeyBoardEventCode["ARROW_LEFT"] = "ArrowLeft";
  KeyBoardEventCode["ARROW_RIGHT"] = "ArrowRight";
  KeyBoardEventCode["ARROW_DOWN"] = "ArrowDown";
  KeyBoardEventCode["ARROW_UP"] = "ArrowUp";
  KeyBoardEventCode["HOME"] = "Home";
  KeyBoardEventCode["END"] = "End";
  KeyBoardEventCode["PAGE_DOWN"] = "PageDown";
  KeyBoardEventCode["PAGE_UP"] = "PageUp";
})(KeyBoardEventCode || (KeyBoardEventCode = {}));
var AsmDialogActionType;
(function (AsmDialogActionType) {
  AsmDialogActionType["NAVIGATE"] = "NAVIGATE";
})(AsmDialogActionType || (AsmDialogActionType = {}));

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/**
 * Generated bundle index. Do not edit.
 */

export { ASM_CUSTOMER_360_CORE_FEATURE, ASM_CUSTOMER_360_FEATURE, AsmCustomer360Facade, AsmCustomer360RootModule, AsmCustomer360SectionConfig, AsmCustomer360SectionData, AsmCustomer360TabConfig, AsmCustomer360TabsConfig, AsmCustomer360Type, AsmDialogActionType, KeyBoardEventCode, PaymentCardCode };
